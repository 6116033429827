import React, { lazy } from "react";

const HomePage = lazy(() => import("../views/pages/Dashboard/index"));
// const play = lazy(() => import("../views/pages/Home/index"));
// const playGame = lazy(() => import("../views/pages/FortuneWheel"));
// const HomeLayout = lazy(() => import("../layouts/HomeLayout"));
// const LoginLayout = lazy(() => import("../layouts/LoginLayout"));
// const HomeLayout1 = lazy(() => import("../layouts/HomeLayout/layout1"));
// const DashboardLayout = lazy(() => import("../layouts/DashboardLayout"));

const routes = [
  {
    exact: true,
    path: "/",
    // component: lazy(() => import("src/Dashboard")),
    element: HomePage,
  },
  // {
  //   component: () => <Redirect to="/404" />,
  // },

  {
    exact: true,
    path: "/404",
    element: lazy(() => import("../views/errors/NotFound")),
  },
];

export default routes;
