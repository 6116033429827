import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiTable: {
      root: {
        border: "1px solid #d83a3a",
      },
    },
    MuiTableRow: {
      root: {
        // borderBottom: "1px solid #d83a3a61",
      },
    },
    MuiDialog: {
      zIndex: "9999999999999",
      root: { zIndex: "9999999999999" },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiSelect: {
      height: "40px",
      root: {},
      select: {
        cursor: "pointer",
        userSelect: "none",
        borderRadius: "0",
        MozAppearance: "none",
        WebkitAppearance: "none",
        background: "#fff",
        padding: "10px 14px",
        color: "#000 !important",
      },
      iconOutlined: {
        color: "#000 !important",
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: "8px",
        border: "1px solid rgba(215, 59, 60, 0.50)",
        background: "rgba(215, 59, 60, 0.10)",
        "&$error $notchedOutline": {
          borderColor: "#ff300f",
        },
      },
      notchedOutline: {
        borderColor: "rgb(255 255 255 / 36%)",
      },

      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiAvatarGroup: {
      avatar: {
        border: "none",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#000",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: "16px",
      },
    },
    MuiFormHelperText: {
      root: {
        "&.Mui-error": {
          color: "#ff300f",
        },
      },
    },

    MuiButtonBase: {
      root: {
        // color: "#fff !important",
      },
    },
    MuiTableCell: {
      root: {
        whiteSpace: "pre",
        padding: "11px 9px",
      },
      head: {
        color: "#fff",
        fontSize: "14px !important",
      },
      body: {
        color: "#151515 !important",
        fontFamily: '"Roboto", sans-serif',
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#999999" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&.Mui-checked": {
          color: "rgba(255, 212, 129, 1) !important",
        },
      },
      root: {
        color: "rgba(255, 212, 129, 1) !important",
      },
    },
    MuiDialog: {
      paperFullWidth: {
        background: "rgba(10, 8, 7, 1)",
        boxShadow:
          "0 1px 0 0 #FFCA64, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #CA772A, -1px 0 0 0 #CA772A, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #CA772A, 1px 1px 0 0 #f4a91b, -1px -1px 0 0 #CA772A",
      },
      paperWidthXs: {
        // maxWidth:"375px"
      },
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "auto",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      root: {
        height: "40px",
      },
      input: {
        fontSize: 14,
        color: "#000",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiIconButton: {
      root: { color: "#a4a8af" },
    },
    MuiButton: {
      root: {
        fontFamily: "Arial",
        fontWeight: "400",
        "&.Mui-disabled": {
          color: "#ac95957a",
        },
      },

      containedPrimary: {
        backgroundColor: "#fcff00",
        borderRadius: "50px",
        color: "#000",
        fontSize: "14px",
        height: "44px",
        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "0px",
        border: "1px solid rgb(255 255 255 / 41%)",
        whiteSpace: "pre",
        "&:hover": {
          color: "#ffffff",
          background: "rgba(255, 255, 255, 0.04) !important",

          border: "1px solid #fcff00",
        },
        "&.Mui-disabled": {
          color: "#ac95957a",
        },
      },

      containedSecondary: {
        backgroundColor: "rgba(255, 255, 255, 0.04)",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "14px",
        height: "44px",
        whiteSpace: "pre",
        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "0px",
        border: "1px solid #fff",
        "&:hover": {
          color: "#fcff00",
          background: "rgba(255, 255, 255, 0.04)",
          backgroundColor: "rgba(255, 255, 255, 0.04)",
          border: "1px solid #fcff00",
        },
        "&.Mui-disabled": {
          color: "#ac95957a",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#2018187a",
        fontWeight: 400,
        padding: "5px 19px",
        "&.Mui-disabled": {
          color: "#ac95957a",
        },
      },

      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
        "&.Mui-disabled": {
          color: "#ac95957a",
        },
      },
      outlinedPrimary: {
        backgroundColor: "#fcff00",
        // borderRadius: "50px",
        color: "#000",
        fontSize: "14px",
        height: "44px",
        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "0px",
        border: "1px solid rgb(255 255 255 / 41%)",
        whiteSpace: "pre",
        "&:hover": {
          color: "#151111",
          background: "#ffffff8f !important",
          // background: "rgba(255, 255, 255, 0.04) !important",

          border: "1px solid #fcff00",
        },
        "&.Mui-disabled": {
          color: "#ac95957a",
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: {
        background: "#d83a3a",
        top: "47px",
      },
    },

    MuiDialogContent: {
      root: {
        padding: "24px",
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: "#fff",
        // fontSize: "14px",
        // fontWeight: 500,
        // lineHeight: " 16px",
        // colorSecondary: {
        //   color: "#8d8989",
        // },
      },
    },
    MuiTypography: {
      subtitle1: {
        color: "#595959",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "Arial",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#ffffff87",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
