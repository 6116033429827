import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";

import { RouterProvider } from "react-router-dom";
import router from "./router";
import AuthContext from "./context/Auth";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "./theme";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mainnet } from "wagmi/chains";
import {
  injectedWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { goerli } from "@wagmi/chains";
const projectId = "e43eedd9ea0a12b486a0ff2a5abf6ba2";

function App() {
  const theme = createTheme();
  const toasterOptions = {
    style: {
      fontSize: "16px", // Adjust the font size here
      wordBreak: "break-word",
      // fontFamily: "Arial",
    },
  };

  //rainbowkit and wagmi connectors implementation for establishing web3 connectivity
  const { chains, publicClient } = configureChains(
    [mainnet],
    [publicProvider()]
  );
  const connectors = connectorsForWallets([
    // ...wallets,
    {
      groupName: "getrepotoken.io",
      wallets: [
        injectedWallet({ projectId, chains }),
        metaMaskWallet({ projectId, chains }),
        // trustWallet({ projectId, chains }),
        // walletConnectWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return (
    <div className="bg-primary w-full overflow-hidden">
      {" "}
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          coolMode
          modalSize="compact"
          theme={darkTheme({
            accentColor: "#D63A3A",
            accentColorForeground: "#fff",
            borderRadius: "large",
            fontStack: "system",
            overlayBlur: "small",
            fontWeight: "500px",
          })}
        >
          {/* <ConnectButton /> */}
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {/* <Hidden xsDown>
                <LandscapeWarning />
              </Hidden>{" "} */}
              <Toaster position="top-center" toastOptions={toasterOptions} />
              <ToastContainer
                position="top-center"
                toastOptions={toasterOptions}
              />
              <AuthContext>
                <RouterProvider router={router} />
              </AuthContext>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;
